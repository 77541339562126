import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/layout'
import Header from '../components/header'
import Quote from '../components/quote'
import Columns from '../components/columns'
import Video from '../components/video'
import image from '../images/dt_cropped.png'

import videos from '../lib/videos'

const IndexPage = () => (
  <Layout>
    <Header
      headline={'A Financial Plan That’s Best For You, Not Us.'}
      image={image}
      copy={
        'We’re a fee based financial advisory company, making your best interest, our best interest. With over 30 years of experience and many long-term clients, the results speak for themselves.  Schedule your free, no obligation consultation today.'
      }
      alignRight={false}
      alignCenter={true}
      caption={true}
      callToAction={
        <Link to={'/contact'}>
          <button>Schedule Your Free Consultation</button>
        </Link>
      }
    />
    <Quote text="Adage Financial Consultants is a full-service retirement planning and investment advisory firm, driven by one focused goal, to help clients grow." />
    <Columns background>
      <div className="marketing__column">
        <h2>
          <Link to={'/financial-planning'}>Financial Planning</Link>
        </h2>
        <p>
          Every day we face challenges managing our finances. Stock market
          swings, fluctuating interest rates, taxes, debt and inflation – the
          effects can be unsettling. One thing is certain; the future will come,
          regardless of whether we are financially ready or not. We can help
          you.
        </p>
      </div>
      <div className="marketing__column">
        <h2>
          <Link to={'/financial-planning/retirement-planning'}>
            Retirement Planning
          </Link>
        </h2>
        <p>
          Baby Boomers are redefining age and retirement. Realizing the
          retirement of your dreams starts with your own personalized definition
          as well as a focused plan. When creating your unique definition of
          retirement, take some time to imagine what your dream retirement
          lifestyle would be like.
        </p>
      </div>
      <div className="marketing__column">
        <h2>
          <Link to={'/financial-planning/investment-services'}>
            Lifetime Income
          </Link>
        </h2>
        <p>
          Protecting your hard-earned assets in perhaps the most volatile era in
          generations can be easier than you think. There may not be a more
          important time than now to safeguard all that you’ve worked so hard to
          achieve.
        </p>
      </div>
    </Columns>
    <Video youTubeUrl={videos.pages.welcome.url} />
  </Layout>
)

export default IndexPage
